import React from "react";

const Step2 = (props) => {
  const { telefoneAtual, onFieldPhoneChange, prevStep, nextStep } = props;

  const handleSubmitStep2 = (e) => {
    e.preventDefault();
    nextStep();
  };

  return (
    <form className="my-5 p-5 form row" onSubmit={handleSubmitStep2}>
      <h3>Telefone</h3>
      <div className="input-group">
        <label className="label">TipoTelefone</label>
        <select
          className="input"
          name="tipoTelefone"
          value={telefoneAtual.tipoTelefone}
          onChange={onFieldPhoneChange}
          required
        >
          <option value="">Selecione o tipo do telefone</option>
          <option value={"Residencial"}>Residencial</option>
          <option value={"Comercial"}>Comercial</option>
          <option value={"Celular"}>Celular</option>
          <option value={"Cobranca"}>Cobranca</option>
        </select>
      </div>
      <div className="input-group">
        <label className="label">DDD</label>
        <input
          className="input"
          type="input"
          name="numeroDoDDD"
          maxLength={2}
          value={telefoneAtual.numeroDoDDD}
          onChange={onFieldPhoneChange}
          required
        ></input>
      </div>
      <div className="input-group">
        <label className="label">Telefone</label>
        <input
          className="input"
          type="input"
          name="numeroDoTelefone"
          maxLength={9}
          value={telefoneAtual.numeroDoTelefone}
          onChange={onFieldPhoneChange}
          required
        ></input>
      </div>
      <br></br>
      <button className="btn btn-primary" onClick={prevStep}>
        Voltar
      </button>
      <br></br>
      <button className="btn btn-secondary" type="submit">
        Avançar
      </button>
    </form>
  );
};

export default Step2;
