import React from "react";
import loadingImg from "../../assets/images/loading.gif";
import "./loading.css";

const Loading = () => {
  return (
    <div className="loaderDiv">
      <div className="loader"></div>
    </div>
    
    /*
    <div>
      <img src={loadingImg} alt="loading" height={50} width={50} />
    </div>*/
  );
};

export default Loading;
