import React from "react";
import cartaoApi from "../../services/cartaodecredito";
import { useState } from "react";
import { useParams } from "react-router-dom";
import "./alterarSenha.css";

const AlterarSenha = (props) => {
  
  const codigodocartao = props.codigodocartao;

  const setComponentRender= props.setComponentRender;

  const [senhaAtual, setSenhaAtual] = useState("");
  const [novaSenha, setNovaSenha] = useState("");

  const SenhaAtual = (event) => {
    setSenhaAtual(event.target.value);
  };
  const NovaSenha = (event) => {
    setNovaSenha(event.target.value);
  };

  const AlterarSenha = async (event) => {
    event.preventDefault();
    const result = await cartaoApi.post(
      "CartaoDeCredito/TrocarSenha/" + codigodocartao,
      {
        SenhaAtual: senhaAtual,
        NovaSenha: novaSenha,
      }
    );
    
  };

  return (
    <div className="">
    <div className="form-senha">
      <form onSubmit={AlterarSenha}>
        <div className="row">
          <div className="col">
            <div className="input-group">
            <label htmlFor="senhaAtual" className="label">
              Senha Atual
            </label>
            <input
              id="senhaAtual"
              name="senhaAtual"
              value={senhaAtual}
              type="password"
              placeholder="Senha Atual"
              onChange={SenhaAtual}
              className="input"
              required
            />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
          <div className="input-group">
            <label htmlFor="novaSenha" className="label">
              Nova senha
            </label>
            <input
              id="novaSenha"
              name="novaSenha"
              value={novaSenha}
              type="password"
              placeholder="Nova senha"
              onChange={NovaSenha}
              className="input"
              required
            />
          </div>
          </div>
        </div>
        <br></br>
        <button className="btn btn-outline-success" type="submit">
          Alterar senha
        </button>
      </form>
    </div>
    </div>
  );
};

export default AlterarSenha;
