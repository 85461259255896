import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./default.css";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Login from "./components/login/login";
import Register from "./components/register/register";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./components/dashboard/dashboard";
import SolicitarCartaoDeCredito from "./components/cartaoDeCredito/SolicitarCartao/SolicitarCartaoDeCredito.tsx";
import DesbloquearCartao from "./components/cartaoDeCredito/DesbloquearCartao.tsx";
import Faturas from "./components/cartaoDeCredito/Faturas.tsx";
import FaturaPorData from "./components/cartaoDeCredito/FaturaPorData.tsx";
import AlterarSenha from "./components/cartaoDeCredito/AlterarSenha.tsx";
import ValidaLogin from "./components/validaLogin/validaLogin.tsx";
import ValidaCadastro from "./components/validaCadastro/validaCadastro.tsx";
import Logout from "./components/logout/logout.tsx";
import RecuperarSenha from "./components/recuperarSenha/RecuperarSenha.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <h1>Page not found</h1>,
    children: [
      { path: "/", element: <Login /> },
      { path: "/login", element: <Login /> },
      { path: "/register", element: <Register /> },
      { path: "/logout", element: <Logout /> },
      { path: "/validaLogin", element: <ValidaLogin /> },
      { path: "/recuperarSenha", element: <RecuperarSenha /> },
      {
        path: "/validarConta",
        element: <ValidaCadastro />,
      },
      {
        path: "/creditcard",
        element: <ProtectedRoute />,
        errorElement: <h1>Page not found</h1>,
        children: [
          {
            path: "/creditcard/solicitar",
            element: <SolicitarCartaoDeCredito />,
          },
          {
            path: "/creditcard/faturas/:conta",
            element: <Faturas />,
          },
          {
            path: "/creditcard/faturaDataVencimento/:conta/:dataVencimento",
            element: <FaturaPorData />,
          },
          {
            path: "/creditcard/alterarsenha/:codigocartao",
            element: <AlterarSenha />,
          },
          {
            path: "/creditcard/desbloquearcartao",
            element: <DesbloquearCartao />,
          },
        ],
      },
      {
        path: "/dashboard",
        element: <ProtectedRoute />,
        errorElement: <h1>Page not found</h1>,
        children: [{ path: "/dashboard/home", element: <Dashboard /> }],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
