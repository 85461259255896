import { toast } from "react-toastify";
import authenticationApi from "./authenticationApi";

const LOGIN_TIME = "LOGIN_TIME";
export const TOKEN_KEY = "@cadplatform";
export const USER_ID = "@cadplatform_id";
export const USER_NAME = "@cadplatform_name";
export const CUSTOMER_ID = "@cadplatform_customerid";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const GetToken = () => {
  const loginTime = localStorage.getItem(LOGIN_TIME);
  const currentTime = Date.now();

  const startTime = () => {
    if (loginTime === null) {
      return null;
    }

    if (currentTime - loginTime > 60 * 60 * 1000) {
      localStorage.clear();
      alert("Sessão expirada, por favor faça login novamente.");
      window.location.href = "/login";
      return null;
    }
  };

  startTime();

  return localStorage.getItem(TOKEN_KEY);
};

export const login = async (email, password) => {
  let data = JSON.stringify({
    Email: email,
    Password: password,
  });

  const retonoApi = await authenticationApi
    .post("/EmailAuthentication", data)
    .catch((error) => {
      if (error.response.status === 400) {
        toast.error("Usuário e/ou senha invalidos!");
        return false;
      } else if (error.response.status === 500) {
        toast.error(
          "Falha ao processar a requisição! Por favor verifique os dados e tente novamente."
        );
        return false;
      } else {
        toast.error(
          "Aparentemente o servidor não está respondendo, tente novamente mais tarde!"
        );
        return false;
      }
    });
  if (retonoApi) {
    const statusApi = retonoApi.status;

    if (statusApi === 200) {
      const data = retonoApi.data;
      localStorage.setItem(USER_ID, data.userId);
      localStorage.setItem(TOKEN_KEY, data.token);
      localStorage.setItem(USER_NAME, data.name);
      localStorage.setItem(LOGIN_TIME, Date.now());
      return true;
    } else if (statusApi === 400) {
      toast.error("Usuário e/ou senha invalidos.");
      return false;
    } else {
      toast.error("Usuário e/ou senha invalidos!");
      return false;
    }
  } else {
    return false;
  }
  /*
  if (retonoApi) {
    const statusApi = retonoApi.status;

    if (statusApi === 200) {
      return true;
    } else if (statusApi === 400) {
      toast.error("Usuário e/ou senha invalidos.");
      return false;
    } else {
      toast.error("Usuário e/ou senha invalidos!");
      return false;
    }
  }
    */
};

export const validarConta = async (email, hash) => {
  let data = JSON.stringify({
    Email: email,
    Hash: hash,
  });

  const retonoApi = await authenticationApi
    .post("/ValidarConta", data)
    .catch((error) => {
      if (error.response.status === 400) {
        return false;
      } else if (error.response.status === 500) {
        toast.error(
          "Falha ao processar a requisição! Por favor verifique os dados e tente novamente."
        );
        return false;
      } else {
        toast.error(
          "Aparentemente o servidor não está respondendo, tente novamente mais tarde!"
        );
        return false;
      }
    });

  if (retonoApi) {
    const statusApi = retonoApi.status;

    if (statusApi === 200) {
      const data = retonoApi.data;
      localStorage.setItem(USER_ID, data.userId);
      localStorage.setItem(TOKEN_KEY, data.token);
      localStorage.setItem(USER_NAME, data.name);
      localStorage.setItem(LOGIN_TIME, Date.now());
      return true;
    } else if (statusApi === 400) {
      toast.error("Usuário e/ou senha invalidos.");
      return false;
    } else {
      toast.error("Usuário e/ou senha invalidos!");
      return false;
    }
  } else {
    return false;
  }
};

export const recuperarSenha = async (email, hash, new_password) => {
  let data = JSON.stringify({
    Email: email,
    validation_code: hash,
    new_password: new_password,
  });

  const retonoApi = await authenticationApi
    .post("/PasswordRecovery", data)
    .catch((error) => {
      if (error.response.status === 400) {
        return false;
      } else if (error.response.status === 500) {
        toast.error(
          "Falha ao processar a requisição! Por favor verifique os dados e tente novamente."
        );
        return false;
      } else {
        toast.error(
          "Aparentemente o servidor não está respondendo, tente novamente mais tarde!"
        );
        return false;
      }
    });

  if (retonoApi) {
    const statusApi = retonoApi.status;

    if (statusApi === 200) {
      return true;
    } else if (statusApi === 400) {
      toast.error("Usuário e/ou senha invalidos.");
      return false;
    } else {
      toast.error("Usuário e/ou senha invalidos!");
      return false;
    }
  } else {
    return false;
  }
};

export const validarCodigoAutenticacao = async (email, codigo) => {
  let data = JSON.stringify({
    Email: email,
    Codigo: codigo,
  });

  const retonoApi = await authenticationApi
    .post("/ValidarCodigoAutenticacao", data)
    .catch((error) => {
      if (error.response.status === 400) {
        return false;
      } else if (error.response.status === 500) {
        toast.error(
          "Falha ao processar a requisição! Por favor verifique os dados e tente novamente."
        );
        return false;
      } else {
        toast.error(
          "Aparentemente o servidor não está respondendo, tente novamente mais tarde!"
        );
        return false;
      }
    });

  if (retonoApi) {
    const statusApi = retonoApi.status;

    if (statusApi === 200) {
      const data = retonoApi.data;
      localStorage.setItem(USER_ID, data.userId);
      localStorage.setItem(TOKEN_KEY, data.token);
      localStorage.setItem(USER_NAME, data.name);
      localStorage.setItem(LOGIN_TIME, Date.now());
      return true;
    } else if (statusApi === 400) {
      toast.error("Usuário e/ou senha invalidos.");
      return false;
    } else {
      toast.error("Usuário e/ou senha invalidos!");
      return false;
    }
  } else {
    return false;
  }
};

export const logout = (navigate) => {
  localStorage.clear();
  navigate("/login");
};
