import React from "react";
import { useState } from "react";
import "./AlterarVencimentoCartao.css";
import cartaoApi from "../../services/cartaodecredito";
import { toast } from "react-toastify";

const AlterarVencimentoCartao = (params) => {
  const [novoVencimento, setVencimento] = useState("");
  const conta = params.conta;

   const handleSubmit = async(e) => {
    e.preventDefault();

    console.log(novoVencimento);

    const vencimento = {
      dataDeVencimento: novoVencimento,
    };
    console.log(vencimento);

    await cartaoApi
      .post("cartaodecredito/DatasVencimento/"+conta, vencimento)
      .then((response) => {
        console.log(response);
        toast.success("Vencimento alterado com sucesso!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Erro ao alterar vencimento!");
      });
  }

  return (
    <div className="container-dados">
      <div className="form">
        <h1>Alterar vencimento da fatura</h1>
        <form>
          <div className="input-group">
            <label htmlFor="vencimento" className="label">
              Novo vencimento
            </label>
            <select
              className="input"
              name="novo_vencimento"
              defaultValue={novoVencimento}
              onChange={(e) =>setVencimento(e.target.value)}
              required
            >
              <option value="">Selecione nova data</option>
              <option value="10">1</option>
              <option value="11">5</option>
              <option value="12">10</option>
              <option value="13">15</option>
              <option value="14">20</option>
              <option value="15">25</option>
            </select>
          </div>
          <br></br>
          <button type="button" className="btn btn-primary" onClick={(e) => handleSubmit(e)}>
            Alterar
          </button>
        </form>
      </div>
    </div>
  );
};

export default AlterarVencimentoCartao;
