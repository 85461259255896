import React, { useEffect, useState } from "react";
import { logout } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { USER_NAME } from "../../services/auth";
import { isAuthenticated } from "../../services/auth";
import "./navbar.css";
import Cartoes from "../cartaoDeCredito/Cartoes.tsx";
import MeusDados from "../cartaoDeCredito/MeusDados.tsx";
import Faturas from "../cartaoDeCredito/Faturas.tsx";
import UltimasTransacoes from "../cartaoDeCredito/UltimasTransacoes.tsx";
import RecebiMeuCartao from "../cartaoDeCredito/RecebiMeuCartao.tsx";
import homoicon from "../../assets/home.svg";
import cadastroicon from "../../assets/cadastro.svg";
import faturasicon from "../../assets/faturas.svg";
import desbloqicon from "../../assets/desbloqueio.svg";
import cartoesicon from "../../assets/cartoes.svg";
import seta from "../../assets/seta.svg";
import AlterarVencimentoCartao from "../cartaoDeCredito/AlterarVencimentoCartao.tsx";

const NavComponent = (props) => {
  const titulo = props.titulo;
  const cartoes = props.cartoes;
  const alterarTitulo = props.alterarTitulo;
  const conta = props.conta;
  const customerId = props.customerId;
  const portador = props.portador;
  const setComponentRender = props.setComponentRender;
  const limiteConta = props.limiteConta;
  const navigate = useNavigate();
  const [logged, setLogged] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  function DoLogout() {
    logout(navigate);
  }

  function GoCatalog() {
    navigate("/dashboard/catalog");
  }

  function onClickInicio(e) {
    //alterarTitulo(e.target.getAttribute("value"));
    setComponentRender(
      <div>
        {" "}
        <UltimasTransacoes conta={conta} onClickDesbloq={onClickDesbloquearNovoCartao} onClickVenc={onClickVenc} />
      </div>
    );
  }

  function onClickMeusDados(e) {
    alterarTitulo(e.target.getAttribute("value"));
    setComponentRender(
      <MeusDados customerId={customerId} portador={portador} />
    );
  }

  function onClickExtrato(e) {
    alterarTitulo(e.target.getAttribute("value"));
    setComponentRender(<Faturas conta={conta} />);
  }

  function onClickDesbloquearNovoCartao(e) {
    alterarTitulo(e.target.getAttribute("value"));
    setComponentRender(<RecebiMeuCartao portador={portador} />);
  }

  function onClickVenc(e) {
    alterarTitulo("Vencimentos");
    setComponentRender(<AlterarVencimentoCartao conta={conta} />);
  }

  function onClickCartoes(e) {
    alterarTitulo(e.target.getAttribute("value"));
    setComponentRender(<Cartoes cartoes={cartoes} limiteConta={limiteConta} />);
  }

  function hiddenTextMenu(){
    const itensToHide = document.querySelectorAll('.menu-item-text');

    itensToHide.forEach((item) => {
      if(item.style.display === 'none'){
        item.style.display = 'block';
        const btn = document.querySelector('.hover-btn-close');
        btn.style.transform = 'rotate(180deg)';
      }else{
        item.style.display = 'none';
        const btn = document.querySelector('.hover-btn-close');
        btn.style.transform = 'rotate(0deg)';
      }
      
    });
  }


  useEffect(() => {
    setLogged(isAuthenticated);
    alterarTitulo("Ultimas transações");
    setComponentRender(
      <div>
        {" "}
        <UltimasTransacoes conta={conta} />
      </div>
    );
  }, []);

  if (!logged) {
    return null;
  }

  return (
    /*
    <nav className="navbar navbar-expand-lg navdash" aria-label="">
      <div className="container-fluid">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample10" aria-controls="navbarsExample10" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-md-center" id="navbarsExample10">
          <ul className="navbar-nav">
          <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#" value={"Ultimas transações"} onClick={onClickInicio}>Ultimas transações</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#" value={"Meus Dados"} onClick={onClickMeusDados}>Meus Dados</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#" value={"Faturas"} onClick={onClickExtrato}>Faturas</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="#" value={"Desbloquear cartão"} onClick={onClickDesbloquearNovoCartao}>Desbloquear Cartão</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    */
    <div className="menu">
      <div className="menu-body">
        <div className="menu-body-list">
          <div className="menu-item" onClick={onClickInicio}>
            <div className="menu-item-icon">
              <img src={homoicon} className="hover-btn-icon-img" />
            </div>
            <div className="menu-item-text">Home</div>
          </div>
          <div className="menu-item" onClick={onClickCartoes}>
            <div className="menu-item-icon">
              <img src={cartoesicon} className="hover-btn-icon-img" />
            </div>
            <div className="menu-item-text">Cartões</div>
          </div>
          <div className="menu-item" onClick={onClickMeusDados}>
            <div className="menu-item-icon">
              <img src={cadastroicon} className="hover-btn-icon-img" />
            </div>
            <div className="menu-item-text">Meus dados</div>
          </div>
          <div className="menu-item" onClick={onClickExtrato}>
            <div className="menu-item-icon">
              <img src={faturasicon} className="hover-btn-icon-img" />
            </div>
            <div className="menu-item-text">Faturas</div>
          </div>
          
          {/*
          <div className="menu-item menu-item-bottom" onClick={onClickDesbloquearNovoCartao}>
            <div className="menu-item-icon">
              <img src={desbloqicon} className="hover-btn-icon-img" />
            </div>
           <div className="menu-item-text">Desbloqueios</div>
          </div>
          */}
        </div>
      </div>
      <div className="hover-btn" onClick={hiddenTextMenu}>
        <div className="hover-btn-close">
          <div className="hover-btn-icon"></div>
          <div className="hover-btn-icon-body">
            <div className="hover-btn-icon-img">
            <img src={seta} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavComponent;
