import React from "react";
import cartaoApi from "../../services/cartaodecredito.js";
import { useState } from "react";
import { useQuery } from "react-query";
import Loading from "../loading/loading.js";

const SaldoLimiteConta = (props) => {
  const conta = props.conta;
  const setLimiteConta = props.setLimiteConta;
  
  const { data: saldoLimite, isFetching: fetchingSaldoLimite } = useQuery<any>(
    "saldoLimiteConta",
    async () => {
      const url = "cartaodecredito/LimiteConta/" + conta;
      const result = await cartaoApi.get(url);
      
      const data = JSON.parse(result.data);
      
      setLimiteConta(data.conteudo?.LimiteDeCompra);
      return data;
    },
    {
      enabled: !!conta && conta !== "undefined",
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 30,
    }
  );

  return fetchingSaldoLimite ? (
    
    <Loading />
    
  ) : (
    <></>
  );
};

export default SaldoLimiteConta;
