import React, { useEffect, useState } from "react";
import { Form, Container } from "react-bootstrap";
import Portador from "../../models/Portador";
import "./MeusDados.css";

const MeusDados = (props) => {
  const [portadorAtual, setPortador] = useState<Portador>({} as Portador);

  useEffect(() => {
    if (props.portador) {
      setPortador(props.portador);
    }
  }, [props.portador]);

  return (
    <div className="container">
      <Form className="form container-dados">
        <div className="">
          <h3>Dados Pessoais</h3>
          <div className="input-group">
            <label className="label">Nome</label>
            <input
              className="input"
              disabled
              type="input"
              name="nome"
              value={portadorAtual.nome}
            ></input>
          </div>

          <div className="input-group">
            <label className="label">Nome Da Mae</label>
            <input
              className="input"
              disabled
              type="input"
              name="nomeDaMae"
              value={portadorAtual.nomeDaMae}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Data De Nascimento</label>
            <input
              className="input"
              disabled
              type="date"
              name="dataDeNascimento"
              value={portadorAtual.dataDeNascimento}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Sexo</label>
            <select
              name="sexo"
              value={portadorAtual.sexo}
              defaultValue={portadorAtual.sexo}
              className="input"
              disabled
            >
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </select>
          </div>
          <div className="input-group">
            <label className="label">CPF</label>
            <input
              className="input"
              disabled
              type="input"
              name="cpf"
              value={portadorAtual.cpf}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Numero Da Identidade</label>
            <input
              className="input"
              disabled
              type="input"
              name="numeroDaIdentidade"
              value={portadorAtual.numeroDaIdentidade}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Orgao Expedidor Da Identidade</label>
            <input
              className="input"
              disabled
              type="input"
              name="orgaoExpedidorDaIdentidade"
              value={portadorAtual.orgaoExpedidorDaIdentidade}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Unidade federativa da Identidade</label>
            <select
              name="unidadeFederativaDaIdentidade"
              className="input"
              disabled
              value={portadorAtual.unidadeFederativaDaIdentidade}
              defaultValue={portadorAtual.unidadeFederativaDaIdentidade}
            >
              <option value="AC">AC</option>
              <option value="AL">AL</option>
              <option value="AP">AP</option>
              <option value="AM">AM</option>
              <option value="BA">BA</option>
              <option value="CE">CE</option>
              <option value="DF">DF</option>
              <option value="ES">ES</option>
              <option value="GO">GO</option>
              <option value="MA">MA</option>
              <option value="MT">MT</option>
              <option value="MS">MS</option>
              <option value="MG">MG</option>
              <option value="PA">PA</option>
              <option value="PB">PB</option>
              <option value="PR">PR</option>
              <option value="PE">PE</option>
              <option value="PI">PI</option>
              <option value="RJ">RJ</option>
              <option value="RN">RN</option>
              <option value="RS">RS</option>
              <option value="RO">RO</option>
              <option value="RR">RR</option>
              <option value="SC">SC</option>
              <option value="SP">SP</option>
              <option value="SE">SE</option>
              <option value="TO">TO</option>
            </select>
          </div>
          <div className="input-group">
            <label className="label">Data De Emissao Da Identidade</label>
            <input
              className="input"
              disabled
              type="date"
              name="dataDeEmissaoDaIdentidade"
              value={portadorAtual.dataDeEmissaoDaIdentidade}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Estado Civil</label>
            <select
              name="estadoCivil"
              value={portadorAtual.estadoCivil}
              defaultValue={portadorAtual.estadoCivil}
              className="input"
            >
              <option value="SOLTEIRO">SOLTEIRO</option>
              <option value="CASADO">CASADO</option>
              <option value="Desquitado">Desquitado</option>
              <option value="Viúvo">VIUVO</option>
              <option value="Divorciado">Divorciado</option>
              <option value="Companheiro">UNIÃO ESTÁVEL</option>
              <option value="Separado">Separado</option>
            </select>
          </div>
          <div className="input-group">
            <label className="label">Codigo Da Profissao</label>
            <select
              name="codigoDaProfissao"
              value={portadorAtual.codigoDaProfissao}
              defaultValue={portadorAtual.codigoDaProfissao}
              placeholder="Selecione a Profissão"
              className="input"
              required
            >
              <option>Selecione a profissão</option>
              <option value={1}>ADMINISTRADOR DE EMPRESAS</option>
              <option value={2}>ADVOGADO</option>
              <option value={3}>
                AEROVIÁRIOS, EMPREGADOS DE AEROPORTO ETC
              </option>
              <option value={4}>
                AGENTE DE VIAGEM, GUIA E OUTROS DO SETOR
              </option>
              <option value={5}>TRABALHADOR AGRÍCOLA</option>
              <option value={6}>AGRÔNOMO</option>
              <option value={7}>APOSENTADOS EM GERAL</option>
              <option value={8}>ARQUITETO</option>
              <option value={9}>ASSIS SOCIAL</option>
              <option value={10}>
                CARGO DIREÇÃO E ASSESSORAMENTO SUPERIOR
              </option>
              <option value={11}>AUTÔNOMO</option>
              <option value={12}>
                CRIAÇÃO DE PEQUENOS ANIMAIS, GRANJAS, ETC.
              </option>
              <option value={13}>BANCÁRIO, ECONOMIÁRIO</option>
              <option value={14}>EMPRESÁRIO</option>
              <option value={15}>
                BIBLIOTECÁRIO, ARQUIVISTA, MUSEÓLOGO, ARQUEÓLOGO
              </option>
              <option value={16}>BIÓLOGO, BIOMÉDICO</option>
              <option value={17}>
                CABELEIREIRO, BARBEIRO, MANICURE, PEDICURE, MAQUILADOR,
                MASSAGISTA
              </option>
              <option value={18}>CARPINTEIRO, MARCENEIRO</option>
              <option value={19}>COMERCIÁRIOS EM GERAL</option>
              <option value={20}>
                PROPRIETÁRIO DE ESTABELECIMENTO COMERCIAL
              </option>
              <option value={21}>
                PROPRIETÁRIO DE PEQUENAS E MICROEMPRESAS, EMPREITEIRO,
                CONSTRUTOR
              </option>
              <option value={22}>CONTADOR</option>
              <option value={23}>
                CORRETOR DE IMÓVEIS, SEGUROS, TÍTULOS E VALORES, BENS, DETETIVE
                PARTICULAR
              </option>
              <option value={24}>ODONTÓLOGO</option>
              <option value={25}>DESENHISTA TÉCNICO, PROJETISTA ETC</option>
              <option value={26}>ECONOMISTA</option>
              <option value={27}>
                DIRETOR, ORIENTADOR PEDAGÓGICO, SECRETARIA DE ESCOLA, ETC.
              </option>
              <option value={28}>
                ELETRICISTA DE MANUTENÇÃO DE VEÍCULOS, MAQUINAS E APARELHOS
              </option>
              <option value={30}>ENFERMEIRO, NUTRICIONISTA</option>
              <option value={31}>ENGENHEIRO</option>
              <option value={32}>PROFISSIONAIS DE LETRAS E ARTES</option>
              <option value={33}>
                ESCULTOR, PINTOR, ARTISTA PLÁSTICO E ASSEMELHADOS
              </option>
              <option value={34}>DESENHISTA COMERCIAL</option>
              <option value={35}>
                ESTAGIÁRIO, BOLSISTA, ESTUDANTE, TRAINEES ETC
              </option>
              <option value={36}>FARMACÊUTICO</option>
              <option value={37}>FERRAMENTEIRO</option>
              <option value={38}>FÍSICO</option>
              <option value={39}>FISIOTERAPEUTA, TERAPEUTA NUTRICIONAL</option>
              <option value={40}>SERVIDOR PUBLICO ESTADUAL</option>
              <option value={41}>SERVIDOR PUBLICO FEDERAL</option>
              <option value={42}>SERVIDOR PUBLICO MUNICIPAL</option>
              <option value={43}>GEÓGRAFO</option>
              <option value={44}>
                SERRALHEIRO, IMPRESSOR, LINOTIPISTA, BLOQUISTA, OPER. OFFSET,
                OPER. ROTATIVA
              </option>
              <option value={47}>
                PROPRIETÁRIO DE ESTABELECIMENTO INDUSTRIAL
              </option>
              <option value={49}>OURIVES, JOALHEIRO</option>
              <option value={50}>JORNALEIRO, FLORISTA</option>
              <option value={51}>JORNALISTA</option>
              <option value={52}>
                AUXILIAR LABORATÓRIO, SAPATEIRO, CHAVEIRO, RELOJOEIRO,
                JARDINEIRO
              </option>
              <option value={53}>
                MECÂNICO DE MANUTENÇÃO DE VEÍCULOS AUTOMOTORES
              </option>
              <option value={54}>MEDICO</option>
              <option value={55}>METALÚRGICO</option>
              <option value={56}>FUNCIONÁRIO DE EMPRESAS PUBLICAS</option>
              <option value={57}>MOTORISTA AUTÔNOMO, TAXISTA</option>
              <option value={58}>MUSICO</option>
              <option value={60}>
                PEDREIRO, ENCANADOR, AZULEJISTA, IMPERMEABILIZADOR, COLOCADOR
                (CARPETES,SINTECO)
              </option>
              <option value={61}>PENSIONISTA</option>
              <option value={62}>TRABALHADOR DA PESCA</option>
              <option value={63}>PINTOR DE PAREDES, GESSISTA</option>
              <option value={64}>
                MINISTRO, GOVERNADOR, PREFEITO, SECRETÁRIO DE ESTADO/MUNICÍPIO
              </option>
              <option value={65}>ESTIVADOR, CARREGADOR, EMBALADOR</option>
              <option value={66}>PSICÓLOGO</option>
              <option value={67}>PROFESSOR DE ENSINO 1º E 2º GRAUS</option>
              <option value={68}>PROMOTOR DE VENDAS E OUTROS</option>
              <option value={69}>
                PUBLICITÁRIO (CONTATO, DIRETOR DE ARTE, REDATOR ETC)
              </option>
              <option value={70}>QUÍMICO</option>
              <option value={71}>
                LOCUTOR, RADIALISTA, COMENTARISTA DE RADIO E TV
              </option>
              <option value={74}>
                REPRESENTANTE COMERCIAL, CAIXEIRO VIAJANTE
              </option>
              <option value={75}>NÃO CLASSIFICADO</option>
              <option value={76}>OUTROS GRUPO 15</option>
              <option value={77}>
                CALCETEIRO, CANTEIRO, MANOBRISTA, VIDRACEIRO
              </option>
              <option value={78}>MODELO DE MODAS</option>
              <option value={79}>FISCAL DE TRAFEGO E OUTROS</option>
              <option value={80}>
                VIGIA, VIGILANTE, GUARDA, SEGURANÇA, BOMBEIRO
              </option>
              <option value={81}>OUTROS GRUPO 12</option>
              <option value={84}>VETERINÁRIO, ZOOTÉCNICO</option>
              <option value={85}>GEÓLOGO</option>
              <option value={86}>OUTROS GRUPO 13</option>
              <option value={87}>EMPREGADOS DOMÉSTICOS EM GERAL</option>
              <option value={88}>SACERDOTE, PADRE, PASTOR</option>
              <option value={89}>SOCIÓLOGO</option>
              <option value={90}>ATUARIO, MATEMÁTICO</option>
              <option value={91}>
                ATLETA PROFISSIONAL E TÉCNICO EM DESPORTOS
              </option>
              <option value={92}>
                MINISTRO DE TRIBUNAL SUPERIOR, JUIZ, DESEMBARGADOR
              </option>
              <option value={93}>
                SECRETARIA, ESTENOGRAFA, DATILOGRAFA, RECEPCIONISTA,
                TELEFONISTA, OPER DE TMK
              </option>
              <option value={94}>
                AUX DE ESCRIT, VENDAS, ADMINIST., ALMOXARIF, ASSISTENTE,
                CALCULISTA, BILHETEIRO
              </option>
              <option value={95}>DIGITADOR</option>
              <option value={96}>OUTROS TÉCNICOS DE NIVEL MEDIO</option>
              <option value={97}>
                PRAÇA (SOLDADO / CABO / SARGENTO / SUBTENENTE) DA PM
              </option>
              <option value={98}>TRAB.CONTÁBIL / CAIXAS E ASSEMELHADOS</option>
              <option value={100}>
                EMPREGADO DE RESTAURANTE, BUFFET, LANCHONETE, PADARIA E
                REFEIÇÕES INDUSTRIAIS
              </option>
              <option value={101}>
                COSTUREIRA / BORDADEIRA / TAPECEIRA / OVERLOQUISTA /
                PESPONTADEIRA
              </option>
              <option value={102}>
                ATENDENTE DE ENFERMAGEM / AUXILIAR DE ENFERMAGEM / AUX CRECHE
              </option>
              <option value={103}>
                VIVE DE RENDA /ALUGUEL / SÓCIO / PROPRIETÁRIO
              </option>
              <option value={104}>DONA DE CASA / PRENDA DOMÉSTICAS</option>
              <option value={105}>
                OPERADOR DE MÁQUINAS DE PRODUÇÃO EM GERAL
              </option>
              <option value={106}>
                CHEFE INTERM/ENCARREGADO SERV/ SUPERV /CHEFES DE SEÇÃO
              </option>
              <option value={107}>
                PORTEIRO / ASCENSORISTA / GARAGISTA / FAXINEIRO
              </option>
              <option value={108}>
                AUX ESCRITÓRIO/ AUX ALMOXARIFADO / CONFERENTE / BILHETEIRO
              </option>
              <option value={109}>EM BRANCO</option>
              <option value={110}>GERENTE</option>
              <option value={111}>
                VENDEDOR AUTÔNOMO/AMBULANTE/CAMELÔS/SACOLEIRAS
              </option>
              <option value={112}>
                VENDEDOR COMÉRCIO VAREJISTA E ATACADISTA
              </option>
              <option value={113}>
                MOTORISTA EMPREGADO DO TRANSPORTE DE PASSAGEIROS
              </option>
              <option value={114}>
                AUXILIAR DE COZINHA/COSTURA/LIMPEZA/COPA
              </option>
              <option value={115}>
                AJUD.GERAL/AUX.SERV.GERAIS/AJ DE PEDREIRO/SERVENTE/AJ DE
                MOTORISTA
              </option>
              <option value={116}>
                OFFICE BOY/CONTÍNUOS/MOTOCICLISTA/CICLISTA
              </option>
            </select>
          </div>
          <div className="input-group">
            <label className="label">Nacionalidade</label>
            <input
              className="input"
              disabled
              type="input"
              name="siglaNacionalidade"
              value={portadorAtual.siglaNacionalidade}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Email</label>
            <input
              className="input"
              disabled
              type="Email"
              name="email"
              value={portadorAtual.email}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Renda</label>
            <input
              className="input"
              disabled
              type="number"
              name="valorRenda"
              value={portadorAtual.valorRenda}
            ></input>
            
              <input
                className="input"
                disabled
                type="hidden"
                name="numeroDaAgencia"
                value={portadorAtual.numeroDaAgencia}
              ></input>
            
            
              <input
                className="input"
                disabled
                type="hidden"
                name="numerodaContaCorrente"
                value={portadorAtual.numerodaContaCorrente}
              ></input>
            

            
              <input
                className="input"
                disabled
                type="hidden"
                name="nomeEmpresa"
                value={portadorAtual.nomeEmpresa}
              ></input>
            
            
              <input
                className="input"
                disabled
                type="hidden"
                name="nome"
                value={portadorAtual.nome}
              ></input>
            
          </div>
        </div>

        <div className="">
          <h3>Telefone</h3>
          <div className="input-group">
            <label className="label">TipoTelefone</label>
            <input
              className="input"
              disabled
              type="input"
              name="tipoTelefone"
              value={portadorAtual?.telefones?.[0]?.tipoTelefone}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">DDD</label>
            <input
              className="input"
              disabled
              type="input"
              name="numeroDoDDD"
              value={portadorAtual?.telefones?.[0]?.numeroDoDDD}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Telefone</label>
            <input
              className="input"
              disabled
              type="input"
              name="numeroDoTelefone"
              value={portadorAtual?.telefones?.[0]?.numeroDoTelefone}
            ></input>
          </div>
        </div>

        <div className="">
          <h3>Endereço</h3>
          <input
            className="input"
            disabled
            type="hidden"
            name="tipoEndereco"
            value={portadorAtual?.enderecos?.[0]?.tipoEndereco}
          ></input>
          <div className="col-12">
            <label className="label">Cep</label>
            <input
              className="input"
              disabled
              type="input"
              name="cep"
              value={portadorAtual?.enderecos?.[0]?.cep}
            ></input>
          </div>

          <div className="input-group">
            <label className="label">Rua</label>
            <input
              className="input"
              disabled
              type="input"
              name="logradouro"
              value={portadorAtual?.enderecos?.[0]?.logradouro}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Numero</label>
            <input
              className="input"
              disabled
              type="input"
              name="numero"
              value={portadorAtual?.enderecos?.[0]?.numero}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Complemento</label>
            <input
              className="input"
              disabled
              type="input"
              name="complemento"
              value={portadorAtual?.enderecos?.[0]?.complemento}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Bairro</label>
            <input
              className="input"
              disabled
              type="input"
              name="bairro"
              value={portadorAtual?.enderecos?.[0]?.bairro}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Cidade</label>
            <input
              className="input"
              disabled
              type="input"
              name="cidade"
              value={portadorAtual?.enderecos?.[0]?.cidade}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Uf</label>
            <input
              className="input"
              disabled
              type="input"
              name="uf"
              value={portadorAtual?.enderecos?.[0]?.uf}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Pais</label>
            <input
              className="input"
              disabled
              type="input"
              name="pais"
              value={portadorAtual?.enderecos?.[0]?.pais}
            ></input>
          </div>
          <div className="input-group">
            <label className="label">Ponto de Referencia</label>
            <input
              className="input"
              disabled
              type="input"
              name="pontoDeReferencia"
              value={portadorAtual?.enderecos?.[0]?.pontoReferencia}
            ></input>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default MeusDados;
