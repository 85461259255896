import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import cartaoApi from "../../services/cartaodecredito";
import { toast } from "react-toastify";
import locker from "../../assets/locker.svg";
import "./DesbloquearCartao.css";



const DesbloquearCartao = (props) => {
  const [code, setCode] = useState("");
  const [checkedBlock, setCheckedBlock] = useState(false);
  const codigodocartao = props.codigodocartao;
  const statusdocartao = props.statusdocartao;
  const navigate = useNavigate();

  const changeBlock = () => {
    setCheckedBlock(!checkedBlock);
    if (checkedBlock) {
      desbloquearCartao();
    } else {
      bloquearCartao();
    }
  };

  

  const desbloquearCartao = async () => {
    try {
      const produtoSolicitado = await cartaoApi.post(
        "/cartaodecredito/desbloquearCartao/" + codigodocartao,
        {}
      );

      const statusUpdateProduct =
        produtoSolicitado.status === 200 ? "success" : "error";

      if (statusUpdateProduct === "success") {
        toast.success("Solicitacao realizada com sucesso!");
        navigate("/dashboard/home");
      } else {
        toast.error(
          "Não foi possível realizar o desbloqueio do cartão! Tente novamente em instantes."
        );
        toast.error("Erro: " + produtoSolicitado.data.message);
      }
    } catch (error) {
      toast.error(
        "Não foi possível realizar o desbloqueio do cartão! Tente novamente em instantes."
      );
    }
  };

  const bloquearCartao = async () => {
    if (window.confirm("Você tem certeza que deseja bloquear o cartão?")) {
      try {
        const produtoSolicitado = await cartaoApi.post(
          "/CartaoDeCredito/Bloquear/" + codigodocartao,
          {}
        );

        const statusUpdateProduct =
          produtoSolicitado.status === 200 ? "success" : "error";

        if (statusUpdateProduct === "success") {
          toast.success("Solicitacao realizada com sucesso!");
          window.location.reload();
        } else {
          toast.error(
            "Não foi possível atualizar o cartão! Tente novamente em instantes."
          );
          toast.error("Erro: " + produtoSolicitado.data.message);
        }
      } catch (error) {
        toast.error(
          "Não foi possível atualizar o cartão! Tente novamente em instantes."
        );
      }
    }
  };

  const OptButton = (props) => {
    const statusdocartao = props.statusdocartao;
    return (
      <>
        <div>
          <span className="bloqueioTemporario">Bloqueio temporário</span>
        </div>

        <div className="blocoBLoqueio">
          <img src={locker} alt="locker" />
          <p className="textoDesbloqueio">
            Você poderá desbloquear seu cartão a qualquer momento
          </p>
          <label className="switch">
            <input className="" type="checkbox" checked={checkedBlock} onChange={changeBlock} />
            <span className="slider round"></span>
          </label>
        </div>
      </>
    );
    {
      /*
    if (statusdocartao === "Bloqueado") {
      return (
        <>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={desbloquearCartao}
        >
          Desbloquear
        </button>
        
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-secondary"
          onClick={bloquearCartao}
        >
          Bloquear
        </button>
      );
    }
      */
    }
  };

  return <OptButton statusdocartao={statusdocartao} />;
};

export default DesbloquearCartao;
