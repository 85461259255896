import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import cartaoApi from "../../services/cartaodecredito";
import locker from "../../assets/locker.svg";
import "./DesbloquearCartao.css";
import { useQuery } from "react-query";

const DesbloquearAproximacaoCartao = (props) => {
  const codigodocartao = props.codigodocartao;
  const [checkedBlock, setCheckedBlock] = useState(false);

  const { data: statusAproximacao, isFetching: fetchingStatusAproximacao } =
    useQuery<any>(
      "statusAproximacao"+codigodocartao,
      async () => {
        const url = "cartaodecredito/ConsultaAproximacao/" + codigodocartao;
        const result = await cartaoApi.get(url);
        const data = result.data;
        console.log("status aproximacao");
        console.log(data);
        setCheckedBlock(data);
        return data;
      },
      {
        enabled: !!codigodocartao && codigodocartao !== "undefined",
        refetchOnWindowFocus: false
      }
    );

    const handleCheckboxChange = (event) => {
      setCheckedBlock(event.target.checked);
      if (event.target.checked) {
        bloquearCartao();
      } else {
        desbloquearCartao();
      }
    };

  const desbloquearCartao = async () => {
    try {
      
      await cartaoApi
        .post("/cartaodecredito/DesbloquearAproximacao/" + codigodocartao, {})
        .then((response) => {
          const produtoSolicitado = response;
          console.log(produtoSolicitado.status);
          const statusUpdateProduct =
            produtoSolicitado.status === 200 ? "success" : "error";
          if (statusUpdateProduct === "success") {
            console.log("Atualizado com sucesso");
            return true;
          } else {

            console.log("Erro ao desbloquear aproximação");
          }
        });
    } catch (error) {
      toast.error(
        "Não foi possível o desbloqueio da aproximação do cartão! Tente novamente em instantes."
      );
    }
  };

  const bloquearCartao = async () => {
    try {
      await cartaoApi
        .post("/cartaodecredito/BloquearAproximacao/" + codigodocartao, {})
        .then((response) => {
          const produtoSolicitado = response;
          console.log(produtoSolicitado.status);
          const statusUpdateProduct =
            produtoSolicitado.status === 200 ? "success" : "error";

          if (statusUpdateProduct === "success") {
            console.log("Atualizado com sucesso");       
            return false;     
          } else {
            toast.error(
              "Não foi possível atualizar o cartão! Tente novamente em instantes."
            );
            toast.error("Erro: " + produtoSolicitado.data.message);
          }
        });
    } catch (error) {
      toast.error(
        "Não foi possível atualizar o cartão! Tente novamente em instantes."
      );
    }
  };

  useEffect(() => {
    if(!fetchingStatusAproximacao){
      console.log("Consulta finalizada");
      console.log("statusAproximacao:"+statusAproximacao);
      //setCheckedBlock(statusAproximacao);
    }
  }, [checkedBlock]);

  return !fetchingStatusAproximacao ? (
    <>
      <div>
        <span className="bloqueioTemporario">Pagamento por aproximação</span>
      </div>
      <div className="blocoBLoqueio">
        <img src={locker} alt="locker" />
        <p className="textoDesbloqueio">
          {checkedBlock ? "Ligado" : "Desligado" }
        </p>
        <label className="switch">
          <input
            type="checkbox"
            checked={checkedBlock}
            onChange={handleCheckboxChange}
          />
          <span className="slider round"></span>
        </label>
      </div>
    </>
  ) : (
    <></>
  );
};

export default DesbloquearAproximacaoCartao;
