import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { recuperarSenha } from "../../services/auth";
import { toast } from "react-toastify";
import NavRendPay from "../navrendpay/navrendpay";
import "./recuperarSenha.css";

const RecuperarSenha = () => {
  const location = useLocation();
  const [novaSenha, setNovaSenha] = useState("");
  const [confirmarNovaSenha, setConfirmarNovaSenha] = useState("");
  const [senhasIguais, setSenhasIguais] = useState(false);
  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  // Obtendo os parâmetros da URL
  const queryParams = getQueryParams(location.search);
  const email = queryParams.get("email");
  const hash = queryParams.get("hash");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(senhasIguais === false) {
        toast.error("As senhas não são iguais!");
        return;
    }

    if (await recuperarSenha(email, hash, novaSenha)) {
      toast.success("Senha atualizada!");
      navigate("/dashboard/home");
    } else {
      toast.error("Não foi possível definir sua senha!");
      navigate("/login");
    }
  };

  const ConfereSenhaIguais = () => {
    if (novaSenha === "") return "";
    if (confirmarNovaSenha === "") return "";
    if (novaSenha !== confirmarNovaSenha) {
      setSenhasIguais(false);
      return "As senhas não são iguais";
    } else {
      setSenhasIguais(true);
      return "";
    }
  };

  return (
    <div className="login">
      <NavRendPay />
      <div className="valid-div">
        <form className="form" data-bitwarden-watching="1">
          <div className="msg">
            <span>Recuperação de senha</span>
            <div className="confira-o-codigo w100">
              <span className="confira">Digite sua nova senha</span>
              <p className="enviado">
                enviado <span className="sms">abaixo!</span>
              </p>
            </div>
          </div>
          <div className="input-group">
            <label className="label">Nova senha</label>
            <input
              type="password"
              className="input"
              placeholder="Nova senha"
              value={novaSenha}
              onChange={(e) => setNovaSenha(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label className="label">Confirmar nova senha</label>
            <input
              type="password"
              className="input"
              placeholder="Confirmar nova senha"
              value={confirmarNovaSenha}
              onChange={(e) => setConfirmarNovaSenha(e.target.value)}
              required
            />
          </div>
          <div>
            <ConfereSenhaIguais />
          </div>
          <div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={(e) => handleSubmit(e)}
            >
              Alterar senha
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RecuperarSenha;
