import { Outlet } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import NavbarDashBoard from "./components/dashboard/navbar";
import { useState, useEffect } from "react";
import NavRendPay from "./components/navrendpay/navrendpay";

const queryClient = new QueryClient();

function App() {
  const [themeState, setThemeState] = useState("light");
  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme) {
      setThemeState(theme);
    }
  }, []);

  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <div className="App">          
          <Outlet />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </div>
      </QueryClientProvider>
    </div>
  );
}

export default App;
