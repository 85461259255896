import React from "react";
import axios from "axios";
import { toast } from "react-toastify";

const Step3 = (props) => {
  const {
    enderecoAtual,
    setEndereco,
    onFieldAddressChange,
    prevStep,
    nextStep,
    handleSubmit,
  } = props;

  const handleSubmitStep3 = (e) => {
    e.preventDefault();
    nextStep();
    handleSubmit(e);
  };

  const findAddress = async (event) => {
    event.preventDefault();
    const cep = enderecoAtual.cep;
    try {
        if (cep.length !== 8) {
          toast.info("CEP inválido");
          return;
        }
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);

      setEndereco({
        ...enderecoAtual,
        tipoEndereco: "PROPRIA",
        logradouro: response.data.logradouro,
        bairro: response.data.bairro,
        cidade: response.data.localidade,
        uf: response.data.uf,
        pais: "Brasil",
        enderecoCorrespondencia: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="form my-5 p-5 row" onSubmit={handleSubmitStep3}>
      <h3>Endereço</h3>
      <input
        className="input"
        type="hidden"
        name="tipoEndereco"
        value={enderecoAtual.tipoEndereco}
      ></input>
      <div className="col-12">
        <label className="label">Cep</label>
        <input
          className="input"
          type="input"
          name="cep"
          value={enderecoAtual.cep}
          onChange={onFieldAddressChange}
          required
        ></input>
        <button className="btn btn-primary" onClick={findAddress}>Buscar Endereço</button>
      </div>

      <div className="input-group">
        <label className="label">Rua</label>
        <input
          className="input"
          type="input"
          name="logradouro"
          value={enderecoAtual.logradouro}
          onChange={onFieldAddressChange}
          required
        ></input>
      </div>
      <div className="input-group">
        <label className="label">Numero</label>
        <input
          className="input"
          type="input"
          name="numero"
          value={enderecoAtual.numero}
          onChange={onFieldAddressChange}
          required
        ></input>
      </div>
      <div className="input-group">
        <label className="label">Complemento</label>
        <input
          className="input"
          type="input"
          name="complemento"
          value={enderecoAtual.complemento}
          onChange={onFieldAddressChange}
          required
        ></input>
      </div>
      <div className="input-group">
        <label className="label">Bairro</label>
        <input
          className="input"
          type="input"
          name="bairro"
          value={enderecoAtual.bairro}
          onChange={onFieldAddressChange}
          required
        ></input>
      </div>
      <div className="input-group">
        <label className="label">Cidade</label>
        <input
          className="input"
          type="input"
          name="cidade"
          value={enderecoAtual.cidade}
          onChange={onFieldAddressChange}
          required
        ></input>
        
      </div>
      <div className="input-group">
        <label className="label">Uf</label>
        <select
          className="input"          
          name="uf"
          value={enderecoAtual.uf}
          onChange={onFieldAddressChange}
          required
        >
          <option value="">Selecione o estado</option>
          <option value="AC">AC</option>
          <option value="AL">AL</option>
          <option value="AP">AP</option>
          <option value="AM">AM</option>
          <option value="BA">BA</option>
          <option value="CE">CE</option>
          <option value="DF">DF</option>
          <option value="ES">ES</option>
          <option value="GO">GO</option>
          <option value="MA">MA</option>
          <option value="MT">MT</option>
          <option value="MS">MS</option>
          <option value="MG">MG</option>
          <option value="PA">PA</option>
          <option value="PB">PB</option>
          <option value="PR">PR</option>
          <option value="PE">PE</option>
          <option value="PI">PI</option>
          <option value="RJ">RJ</option>
          <option value="RN">RN</option>
          <option value="RS">RS</option>
          <option value="RO">RO</option>
          <option value="RR">RR</option>
          <option value="SC">SC</option>
          <option value="SP">SP</option>
          <option value="SE">SE</option>
          <option value="TO">TO</option>
        </select>
      </div>

      <input
        type="hidden"
        name="pais"
        value={enderecoAtual.pais}
        onChange={onFieldAddressChange}
        required
      ></input>

      <div className="input-group">
        <label className="label">Ponto de Referencia</label>
        <input
          className="input"
          type="input"
          name="pontoDeReferencia"
          value={enderecoAtual.pontoReferencia}
          onChange={onFieldAddressChange}
          required
        ></input>
      </div>
      <br></br>
      <button className="btn btn-primary" onClick={prevStep}>
        Voltar
      </button>
      <br></br>
      <button className="btn btn-secondary" type="submit">
        Solicitar
      </button>
    </form>
  );
};

export default Step3;
