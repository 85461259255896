import React, { useEffect, useState } from "react";
import Logo from "../../assets/logolabs.svg";
import locker from "../../assets/iconeslocker.svg";
import info from "../../assets/iconesinfo.svg";
import "./navrendpay.css";

const NavRendPay = () => {
  return (
    /*<nav
      className="navbar navbar-expand-md navrendpay"
      aria-label="Fourth navbar example"
    >
      <div className="container-fluid">
        <a className="navbar-brand" href="#">
          <img src={Logo} alt="Logo" className="logo-login" />
        </a>

        <div className="collapse navbar-collapse" id="navbarsExample04">
          <ul className="navbar-nav me-auto mb-2 mb-md-0"></ul>
          <div className="icon-grp">
            <img src={locker} alt="locker" height={30} />
            <img src={info} alt="locker" height={30} />
          </div>
        </div>
      </div>
    </nav>*/
    <div className="navrendpay">
      <div className="div-logo">
        <img src={Logo} alt="Logo" className="logo-labs" />
      </div>
      <div className="icon-div">
        <span className="msgconexao">Conexão privada e ambiente seguro</span>
        <div className="icon-group">
          <img src={locker} alt="locker" className="icon" />
          <img src={info} alt="locker" className="icon" />
        </div>
      </div>
    </div>
  );
};

export default NavRendPay;
