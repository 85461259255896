import React from "react";
import { useQuery } from "react-query";
import cartaoApi from "../../services/cartaodecredito";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/auth";

const AcompanharSolicitacao = (props) => {
  const customerId = props.customerId;
  const numeroDocumento = props.numeroDocumento;
  const navigate = useNavigate();

  const fetchSolicitacao = async () => {
    const response = await cartaoApi.get(
      "cartaodecredito/ConsultaSolicitacao/" + customerId
    );

    return response.data;
  };

  const fetchCqrs = async () => {
    const response = await cartaoApi.get(
      "cartaodecredito/CQRS/" + numeroDocumento
    );
    const data = response.data.conteudo;

    if (data === undefined) {
      return { resultados: [] };
    }

    return data;
  };

  const {
    data: solicitacao,
    isLoading: loadingSolicitacao,
    isFetching: fetchingSolicitacao,
    error: ErrorSolicitacao,
  } = useQuery("user_solicitation", fetchSolicitacao, {
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 5,
  });

  const {
    data: cqrs,
    isLoading: loadingcqrs,
    isFetching: fetchingcqrs,
    error: Errorcqrs,
  } = useQuery("user_cqrs", fetchCqrs, {
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 5,
  });

  const doLogout = () => {
    logout(navigate);
  };

  if (
    loadingSolicitacao ||
    fetchingSolicitacao ||
    loadingcqrs ||
    fetchingcqrs
  ) {
    return <div>Loading...</div>;
  } else {
    if (!solicitacao) {
      navigate("/creditcard/solicitar");
    } else {
      return (
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h1>Sua Solicitação</h1>
            </div>
            <div className="card-body">
              <p>
                Análise de crédito em andamento... prazo estimado (3 dias úteis)
              </p>

              <p>
                {
                  solicitacao.analiseCreditoResponse.conteudo
                    .codigoParaAcompanhamento
                }
              </p>
              <button className="btn-sair" onClick={doLogout}>
                  Sair
                </button>
            </div>
          </div>
          {"resultados" in cqrs ? (
            <div>
              {cqrs.resultados?.map((item, key) => () => {
                <article key={key}>
                  <h2>{item.CodigoParaAcompanhamento}</h2>
                  <p>{item.CodigoParaAcompanhamento}</p>
                </article>;
              })}
            </div>
          ) : (
            <div>
              <article>
                <h2>Historico não dísponível</h2>
                <p>
                  Recebemos sua solicitação e estamos trabalhando nela, logo
                  novas informações serão disponibilizadas.!
                </p>
                
              </article>
            </div>
          )}
        </div>
      );
    }
  }
};

export default AcompanharSolicitacao;
