import React from 'react';
import { useNavigate } from 'react-router-dom';
import Login from '../login/login';

const Logout = () => {

    const navigate = useNavigate();
    localStorage.clear();
    navigate("/login");
    
  return (
    <Login />
  );
}

export default Logout;