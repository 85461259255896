import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import cartaoApi from "../../../services/cartaodecredito";
import customerApi from "../../../services/CustomersApi";
import { useQuery } from "react-query";
import { USER_ID } from "../../../services/auth";
import { toast } from "react-toastify";
import NavRendPay from "../../navrendpay/navrendpay";
import { logout } from "../../../services/auth";
import Step1 from "./Step1.tsx";
import Step2 from "./Step2.tsx";
import Step3 from "./Step3.tsx";
import Endereco from "../../../models/Endereco.tsx";
import Telefone from "../../../models/Telefone.tsx";
import Portador from "../../../models/Portador.tsx";

const SolicitarCartaoDeCredito = () => {
  const userId = localStorage.getItem(USER_ID);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState([]);
  const [portadorAtual, setPortador] = useState<Portador>({} as Portador);
  const [enderecoAtual, setEndereco] = useState<Endereco>({} as Endereco);
  const [telefoneAtual, setTelefone] = useState<Telefone>({} as Telefone);

  const [step, setStep] = useState(1);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const { data: customer, isFetching: fetchingCustomer } = useQuery<any>(
    "user_customer",
    async () => {
      const response = await customerApi.get("customers/userid/" + userId);
      return response.data;
    },
    { refetchOnWindowFocus: false, refetchInterval: 1000 * 60 * 30 }
  );

  function onFieldChange(e) {
    const { name, value } = e.target;
    const valueToSave = value;
    if (name === "valorRenda") {
      valueToSave.replace(",", "").replace(".", "");
      valueToSave.replace(/[.,]/g, "");
    }
    setPortador({ ...portadorAtual, [name]: valueToSave });
  }

  function onFieldAddressChange(e) {
    const { name, value } = e.target;
    setEndereco({ ...enderecoAtual, [name]: value });
  }

  function onFieldPhoneChange(e) {
    const { name, value } = e.target;
    setTelefone({ ...telefoneAtual, [name]: value });
  }

  // Submit form data
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(portadorAtual);
    let portador: Portador = portadorAtual;
    portador.enderecos = [enderecoAtual];
    portador.enderecos[0].tipoEndereco = "PROPRIA";
    portador.telefones = [telefoneAtual];

    console.log(portador);

    const customerId = customer.id;
    const retornoApi = await customerApi
      .put("customers/" + customerId, portador)
      .catch((error) => {
        toast.error("Erro ao atualizar os dados do portador!");
        console.error(error);
      });

    if (retornoApi?.status === 200) {
      const retornoCartao = await cartaoApi.post(
        "/CartaoDeCredito/" + customerId,
        {}
      );

      console.log(retornoCartao);

      if (retornoCartao?.data?.sucesso === "true") {
        toast.success(
          "Solicitação de cartão de crédito realizada com sucesso!"
        );
        navigate("/dashboard/home");
      } else {
        toast.error("Dados inconsistentes!");
        console.log(retornoCartao.data.erros);
        let mensagemErro = [];

        for (let i = 0; i < retornoCartao.data.erros.length; i++) {
          const mensagem = retornoCartao.data.erros[i].mensagem;
          mensagemErro.push(mensagem); // Corrigido aqui
        }

        console.log(mensagemErro);
        setErrorMessage(mensagemErro);
        setStep(1);
      }
    } else {
      console.log(retornoApi);
    }
  };

  const doLogout = () => {
    logout(navigate);
  };

  return fetchingCustomer ? (
    () => (
      <div>
        <NavRendPay />
        Loading...
      </div>
    )
  ) : (
    <div>
      <NavRendPay />
      <div className="container p-4">
        <div className="container-fluid m-4">
          <button className="btn btn-secondary" onClick={doLogout}>
            Sair
          </button>
          <h3>Solicitar Cartão de Crédito RendPay</h3>
          <div className="card">
            <div className="card-body">
              <p>
                Parece que ainda não tem um cartão de crédito em seu nome.
                Solicite um agora mesmo! Preencha os dados abaixo e aguarda a
                chegada se seu cartão.
              </p>
            </div>
          </div>
        </div>
        <div>
          {errorMessage.map((error, index) => {
            return (
              <div key={index} className="alert alert-danger" role="alert">
                {error}
              </div>
            );
          })}
        </div>
        <div>
          {step === 1 ? (
            <Step1
              portadorAtual={portadorAtual}
              onFieldChange={onFieldChange}
              nextStep={nextStep}
            />
          ) : null}
          {step === 2 ? (
            <Step2
              telefoneAtual={telefoneAtual}
              onFieldPhoneChange={onFieldPhoneChange}
              prevStep={prevStep}
              nextStep={nextStep}
            />
          ) : null}
          {step === 3 ? (
            <Step3
              enderecoAtual={enderecoAtual}
              setEndereco={setEndereco}
              onFieldAddressChange={onFieldAddressChange}
              prevStep={prevStep}
              nextStep={nextStep}
              handleSubmit={handleSubmit}
            />
          ) : null}
          {step === 4 ? (
            <div>
              <h1>Por favor aguarde enquanto processamos sua solicitação....</h1>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SolicitarCartaoDeCredito;
