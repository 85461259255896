import React, { useState } from "react";
import cartaoApi from "../../services/cartaodecredito.js";
import "./CartaoDeCredito.css";
import visa from "../../assets/visa.svg";
import chip from "../../assets/chip.png";
import group from "../../assets/Group.svg";
import logoRend from "../../assets/logorend.png";
import visablack from "../../assets/visablack.png";
import senhaIcon from "../../assets/senhaIcon.png";
import limitesIcon from "../../assets/limites.svg";
import openArrow from "../../assets/openArrow.svg";
import closeArrow from "../../assets/closeArrow.svg";
import DesbloquearCartao from "./DesbloquearCartao.tsx";
import SaldoLimiteCartao from "./SaldoLimiteCartao.tsx";
import AlterarSenha from "./AlterarSenha.tsx";
import AlterarLimiteCartao from "./AlterarLimiteCartao.tsx";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import DesbloquearAproximacaoCartao from "./DesbloquearAproximacao.tsx";
const CartaoDeCredito = (params) => {
  const { cartao } = params;

  const [saldo, setSaldo] = useState(0);
  const [limite, setLimite] = useState(0);
  const limiteConta = params.limiteConta;

  const [hideElement, setHideElement] = useState("");
  const [arrowBtn, setArrowBtn] = useState(closeArrow);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [componentTitle, setComponentTitle] = useState("");
  const [componentRender, setComponentRender] = useState<React.ReactNode>(null);


  function onClickSenha() {
    setComponentTitle("Alterar senha");
    setComponentRender(<AlterarSenha codigodocartao={cartao.codigodocartao} />);
    setShow(true);
  }

  function onHideBtnOption() {
    if (hideElement === "") {
      setHideElement("hide");
      setArrowBtn(openArrow);
    } else {
      setHideElement("");
      setArrowBtn(closeArrow);
    }
  }

  function onClickLimite() {
     setComponentTitle("Meu limite");
    setComponentRender(
      <AlterarLimiteCartao
        codigodocartao={cartao.codigodocartao}
        limiteAtual={limite}
        limiteConta={limiteConta}
      />
    );
    setShow(true);
  }

  return cartao?.statusdocartao == "Normal" ? (
    <div className="cardbody">
      <div className="desenhoCartao">
        <div className="chipimg">
          <img src={chip}></img>
          <img src={group}></img>
        </div>

        <div className="textoCartao">
          <span className="cartaoFinal">Cartão Final</span>
          <p className="numeroCartao">{cartao?.quatroultimosdigitos}</p>
        </div>
        <div>
          <img src={logoRend}></img>
          <img src={visablack}></img>
        </div>
      </div>
      <div className="operacoesCartao">
        <div className="headerCard">
          <div>
            <div className="textTitular">Titular</div>
            <div className="nomeTitular">{cartao?.nomeimpresso}</div>
          </div>
          <div>
            <button onClick={onHideBtnOption} className="hideBtn">
              <img src={arrowBtn}></img>
            </button>
          </div>
        </div>
        {cartao?.statusdocartao === "Normal" ? null : (
          <p>{cartao?.statusdocartao}</p>
        )}

        {cartao?.statusdocartao !== "Cancelado" ? (
          <div>
            <div className={hideElement}>
              <div className="btnsCartao">
                <div className="btnCartao" onClick={onClickSenha}>
                  <img src={senhaIcon}></img>
                  <span className="btnCartaoTxt">Alterar senha</span>
                </div>
                <div className="btnCartao" onClick={onClickLimite}>
                  <img src={limitesIcon}></img>
                  <span className="btnCartaoTxt">Alterar limite</span>
                </div>
              </div>
              <br></br>
              <div>
                <DesbloquearCartao
                  codigodocartao={cartao?.codigodocartao}
                  statusdocartao={cartao?.statusdocartao}
                />
              </div>
              <div>
                <DesbloquearAproximacaoCartao 
                  codigodocartao={cartao?.codigodocartao}
                />
              </div>
            </div>

            <SaldoLimiteCartao
              codigodocartao={cartao?.codigodocartao}
              setSaldo={setSaldo}
              setLimite={setLimite}
              limiteAtual={limite}
              limiteConta={limiteConta}
            />
          </div>
        ) : null}
        
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>{componentTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {componentRender}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  ) : (
    <>
      <div>Nenhum cartão desbloqueado</div>
    </>
  );
};

export default CartaoDeCredito;
