import React, { useState, useEffect } from "react";
import cartaoApi from "../../services/cartaodecredito.js";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import "./UltimasTransacoes.css";
import Loading from "../loading/loading.js";
import clubIris from "../../assets/logo-clubeiris-beneficios_298x115-clube.png";

const UltimasTransacoes = (props) => {
  const [faturas, setFaturas] = useState([]);
  const onClickDesbloq = props.onClickDesbloq;
  const onClickVenc = props.onClickVenc;
  const conta = props.conta;

  const { data: ultimasTransacoes, isFetching: fetchingUltimasTransacoes } =
    useQuery<any>(
      "ultimasTransacoes",
      async () => {
        const url = "cartaodecredito/Transacoes/" + conta;
        const result = await cartaoApi.get(url);
        const data = result.data;
        return data;
      },
      {
        enabled: !!conta && conta !== "undefined",
        refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 30,
      }
    );
  const FormatDate = (stringDate) => {
    const dataString = stringDate.split("/");
    console.log(dataString);
    const day = dataString[0];
    const monthBill = dataString[1] - 1;
    var months = [
      "JAN",
      "FEV",
      "MAR",
      "ABR",
      "MAI",
      "JUN",
      "JUL",
      "AGO",
      "SET",
      "OUT",
      "NOV",
      "DEZ",
    ];

    const dateReturn = day + "/" + months[monthBill];

    return dateReturn;
  };

  const ExibeTransacoes = (transacoes) => {
    if (transacoes !== undefined && transacoes !== null) {
      return (
        <>
          <p>Nenhuma transação para exibir</p>
        </>
      );
    }

    return (
      <div>
        <h4>Últimas transações</h4>
        <ul className="transactionList">
          {ultimasTransacoes?.map((lancamento, index) => (
            <li>
              <div key={index} className="linhaLancamento">
                <div className="linhaLancamento-data">
                  {FormatDate(lancamento?.dataDaCompra.split(" ")[0])}
                </div>
                <div className="linhaLancamento-descricao">
                  {lancamento?.nomeFantasiaDoEstabelecimento}
                </div>
                <div className="linhaLancamento-valor">
                  {lancamento.valor.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div>
      <div>
        <div className="btn btn-secondary" onClick={onClickDesbloq}>
          Desbloquear um cartão
        </div>
        <div className="btn btn-secondary" onClick={onClickVenc}>
          Alterar vencimento da fatura
        </div>
      </div>
      <br></br>
      <br></br>
      <div>
        <span>O <a href="https://clubeiris.clubeparcerias.com.br/" target="_blank">
        Clube Iris
        </a> oferece benefícios e convênios exclusivos em diversos estabelecimentos. Acesse e confira.</span>
        
      </div>
      <br></br>
      {!fetchingUltimasTransacoes ? (
        <>
        <p>Nenhuma transação para exibir</p>
      </>
      ) : (
        <div>
          <ExibeTransacoes transacoes={ultimasTransacoes}></ExibeTransacoes>
        </div>
      )}
    </div>
  );
};

export default UltimasTransacoes;
