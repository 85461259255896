import axios from "axios";
import { GetToken } from "./auth";

const CustomerApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL + "api/customers"
  
});

CustomerApi.interceptors.request.use(async config => {
  const token = GetToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers["Content-Type"] = "application/json";
  config.headers["Access-Control-Allow-Origin"] = "https://portal.hom.rendlabs.com.br/";
  config.headers["Access-Control-Allow-Credentials"] = "true";
  return config;
});

export default CustomerApi;