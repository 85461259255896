import React from "react";
import $ from 'jquery'; // Importa o jQuery
import { MascaraCPF, MascaraInteiro, MascaraRg } from "../../../mask";

const Step1 = (props) => {
  const { portadorAtual, onFieldChange, nextStep } = props;

  const handleSubmitStep1 = (e) => {
    e.preventDefault();
    nextStep();
  };

  
    $(document).on('keypress', 'input.only-number', function(e) {
      var $this = $(this);
      var key = e.which;
      console.log(key);
      var dataAcceptDot = $this.data('accept-dot');
      var dataAcceptComma = $this.data('accept-comma');
      var acceptDot = (typeof dataAcceptDot !== 'undefined' && (dataAcceptDot == true || dataAcceptDot == 1)?true:false);
      var acceptComma = (typeof dataAcceptComma !== 'undefined' && (dataAcceptComma == true || dataAcceptComma == 1)?true:false);
  
      if((key > 47 && key < 58)
        || (key == 46 && acceptDot)
        || (key == 44 && acceptComma)) {
        return true;
      } else {
         return (key == 8 || key == 0)?true:false;
       }
    });
  

  return (
    <form className="form row" onSubmit={handleSubmitStep1}>
      <div className="input-group">
        <label className="label">Nome</label>
        <input
          className="input"
          type="input"
          name="nome"
          minLength={1}
          maxLength={60}
          placeholder="Nome Completo"
          value={portadorAtual.nome}
          onChange={onFieldChange}
          required
        ></input>
      </div>

      <div className="input-group">
        <label className="label">Nome Impresso no cartão</label>
        <input
          className="input"
          type="input"
          name="nomeImpresso"
          minLength={1}
          maxLength={20}
          placeholder="Nome Impresso no cartão"
          value={portadorAtual.nomeImpresso}
          onChange={onFieldChange}
          required
        ></input>
      </div>

      <div className="input-group">
        <label className="label">Nome Da Mae</label>
        <input
          className="input"
          type="input"
          name="nomeDaMae"
          placeholder="Nome da Mãe"
          minLength={1}
          maxLength={60}
          value={portadorAtual.nomeDaMae}
          onChange={onFieldChange}
          required
        ></input>
      </div>
      <div className="input-group">
        <label className="label">Data De Nascimento</label>
        <input
          className="input"
          type="date"
          name="dataDeNascimento"
          value={portadorAtual.dataDeNascimento}
          onChange={onFieldChange}
          required
        ></input>
      </div>
      <div className="input-group">
        <label className="label">Sexo</label>
        <select
          name="sexo"
          value={portadorAtual.sexo}
          onChange={onFieldChange}
          className="input"
          required
        >
          <option value="">Selecione o sexo</option>
          <option value="Masculino">Masculino</option>
          <option value="Feminino">Feminino</option>
        </select>
      </div>
      <div className="input-group">
        <label className="label">CPF</label>
        <input
          className="input"
          name="cpf"
          maxLength={14}
          value={MascaraCPF(portadorAtual.cpf || "")}
          onChange={onFieldChange}
          defaultValue={""}
          required
        ></input>
      </div>
      <div className="input-group">
        <label className="label">Numero Da Identidade</label>
        <input
          className="input"
          name="numeroDaIdentidade"
          value={MascaraRg(portadorAtual.numeroDaIdentidade || "")}
          onChange={onFieldChange}
          maxLength={12}
          defaultValue={""}
          required
        ></input>
      </div>
      <div className="input-group">
        <label className="label">Orgao Expedidor Da Identidade</label>
        <input
          className="input"
          type="input"
          name="orgaoExpedidorDaIdentidade"
          value={portadorAtual.orgaoExpedidorDaIdentidade}
          onChange={onFieldChange}
          required
        ></input>
      </div>
      <div className="input-group">
        <label className="label">Unidade federativa da Identidade</label>
        <select
          name="unidadeFederativaDaIdentidade"
          className="input"
          value={portadorAtual.unidadeFederativaDaIdentidade}
          onChange={onFieldChange}
          required
        >
          <option value="">Selecione o estado emissor</option>
          <option value="AC">AC</option>
          <option value="AL">AL</option>
          <option value="AP">AP</option>
          <option value="AM">AM</option>
          <option value="BA">BA</option>
          <option value="CE">CE</option>
          <option value="DF">DF</option>
          <option value="ES">ES</option>
          <option value="GO">GO</option>
          <option value="MA">MA</option>
          <option value="MT">MT</option>
          <option value="MS">MS</option>
          <option value="MG">MG</option>
          <option value="PA">PA</option>
          <option value="PB">PB</option>
          <option value="PR">PR</option>
          <option value="PE">PE</option>
          <option value="PI">PI</option>
          <option value="RJ">RJ</option>
          <option value="RN">RN</option>
          <option value="RS">RS</option>
          <option value="RO">RO</option>
          <option value="RR">RR</option>
          <option value="SC">SC</option>
          <option value="SP">SP</option>
          <option value="SE">SE</option>
          <option value="TO">TO</option>
        </select>
      </div>
      <div className="input-group">
        <label className="label">Data De Emissao Da Identidade</label>
        <input
          className="input"
          type="date"
          name="dataDeEmissaoDaIdentidade"
          value={portadorAtual.dataDeEmissaoDaIdentidade}
          onChange={onFieldChange}
          required
        ></input>
      </div>
      <div className="input-group">
        <label className="label">Estado Civil</label>
        <select
          name="estadoCivil"
          value={portadorAtual.estadoCivil}
          onChange={onFieldChange}
          className="input"
          required
        >
          <option value="">Selecione o estado cívil</option>  
          <option value="Solteiro">Solteiro</option>
          <option value="Casado">Casado</option>
          <option value="Desquitado">Desquitado</option>
          <option value="Viúvo">Viúvo</option>
          <option value="Divorciado">Divorciado</option>
          <option value="Companheiro">Companheiro</option>
          <option value="Separado">Separado</option>
        </select>
      </div>
      <div className="input-group">
        <label className="label">Profissao</label>
        <select
          name="codigoDaProfissao"
          value={portadorAtual.codigoDaProfissao}
          onChange={onFieldChange}
          className="input"
          required
        >
          <option value="">Selecione a profissão</option>
          <option value={1}>ADMINISTRADOR DE EMPRESAS</option>
          <option value={2}>ADVOGADO</option>
          <option value={3}>AEROVIÁRIOS, EMPREGADOS DE AEROPORTO ETC</option>
          <option value={4}>AGENTE DE VIAGEM, GUIA E OUTROS DO SETOR</option>
          <option value={5}>TRABALHADOR AGRÍCOLA</option>
          <option value={6}>AGRÔNOMO</option>
          <option value={7}>APOSENTADOS EM GERAL</option>
          <option value={8}>ARQUITETO</option>
          <option value={9}>ASSIS SOCIAL</option>
          <option value={10}>CARGO DIREÇÃO E ASSESSORAMENTO SUPERIOR</option>
          <option value={11}>AUTÔNOMO</option>
          <option value={12}>CRIAÇÃO DE PEQUENOS ANIMAIS, GRANJAS, ETC.</option>
          <option value={13}>BANCÁRIO, ECONOMIÁRIO</option>
          <option value={14}>EMPRESÁRIO</option>
          <option value={15}>
            BIBLIOTECÁRIO, ARQUIVISTA, MUSEÓLOGO, ARQUEÓLOGO
          </option>
          <option value={16}>BIÓLOGO, BIOMÉDICO</option>
          <option value={17}>
            CABELEIREIRO, BARBEIRO, MANICURE, PEDICURE, MAQUILADOR, MASSAGISTA
          </option>
          <option value={18}>CARPINTEIRO, MARCENEIRO</option>
          <option value={19}>COMERCIÁRIOS EM GERAL</option>
          <option value={20}>PROPRIETÁRIO DE ESTABELECIMENTO COMERCIAL</option>
          <option value={21}>
            PROPRIETÁRIO DE PEQUENAS E MICROEMPRESAS, EMPREITEIRO, CONSTRUTOR
          </option>
          <option value={22}>CONTADOR</option>
          <option value={23}>
            CORRETOR DE IMÓVEIS, SEGUROS, TÍTULOS E VALORES, BENS, DETETIVE
            PARTICULAR
          </option>
          <option value={24}>ODONTÓLOGO</option>
          <option value={25}>DESENHISTA TÉCNICO, PROJETISTA ETC</option>
          <option value={26}>ECONOMISTA</option>
          <option value={27}>
            DIRETOR, ORIENTADOR PEDAGÓGICO, SECRETARIA DE ESCOLA, ETC.
          </option>
          <option value={28}>
            ELETRICISTA DE MANUTENÇÃO DE VEÍCULOS, MAQUINAS E APARELHOS
          </option>
          <option value={30}>ENFERMEIRO, NUTRICIONISTA</option>
          <option value={31}>ENGENHEIRO</option>
          <option value={32}>PROFISSIONAIS DE LETRAS E ARTES</option>
          <option value={33}>
            ESCULTOR, PINTOR, ARTISTA PLÁSTICO E ASSEMELHADOS
          </option>
          <option value={34}>DESENHISTA COMERCIAL</option>
          <option value={35}>
            ESTAGIÁRIO, BOLSISTA, ESTUDANTE, TRAINEES ETC
          </option>
          <option value={36}>FARMACÊUTICO</option>
          <option value={37}>FERRAMENTEIRO</option>
          <option value={38}>FÍSICO</option>
          <option value={39}>FISIOTERAPEUTA, TERAPEUTA NUTRICIONAL</option>
          <option value={40}>SERVIDOR PUBLICO ESTADUAL</option>
          <option value={41}>SERVIDOR PUBLICO FEDERAL</option>
          <option value={42}>SERVIDOR PUBLICO MUNICIPAL</option>
          <option value={43}>GEÓGRAFO</option>
          <option value={44}>
            SERRALHEIRO, IMPRESSOR, LINOTIPISTA, BLOQUISTA, OPER. OFFSET, OPER.
            ROTATIVA
          </option>
          <option value={47}>PROPRIETÁRIO DE ESTABELECIMENTO INDUSTRIAL</option>
          <option value={49}>OURIVES, JOALHEIRO</option>
          <option value={50}>JORNALEIRO, FLORISTA</option>
          <option value={51}>JORNALISTA</option>
          <option value={52}>
            AUXILIAR LABORATÓRIO, SAPATEIRO, CHAVEIRO, RELOJOEIRO, JARDINEIRO
          </option>
          <option value={53}>
            MECÂNICO DE MANUTENÇÃO DE VEÍCULOS AUTOMOTORES
          </option>
          <option value={54}>MEDICO</option>
          <option value={55}>METALÚRGICO</option>
          <option value={56}>FUNCIONÁRIO DE EMPRESAS PUBLICAS</option>
          <option value={57}>MOTORISTA AUTÔNOMO, TAXISTA</option>
          <option value={58}>MUSICO</option>
          <option value={60}>
            PEDREIRO, ENCANADOR, AZULEJISTA, IMPERMEABILIZADOR, COLOCADOR
            (CARPETES,SINTECO)
          </option>
          <option value={61}>PENSIONISTA</option>
          <option value={62}>TRABALHADOR DA PESCA</option>
          <option value={63}>PINTOR DE PAREDES, GESSISTA</option>
          <option value={64}>
            MINISTRO, GOVERNADOR, PREFEITO, SECRETÁRIO DE ESTADO/MUNICÍPIO
          </option>
          <option value={65}>ESTIVADOR, CARREGADOR, EMBALADOR</option>
          <option value={66}>PSICÓLOGO</option>
          <option value={67}>PROFESSOR DE ENSINO 1º E 2º GRAUS</option>
          <option value={68}>PROMOTOR DE VENDAS E OUTROS</option>
          <option value={69}>
            PUBLICITÁRIO (CONTATO, DIRETOR DE ARTE, REDATOR ETC)
          </option>
          <option value={70}>QUÍMICO</option>
          <option value={71}>
            LOCUTOR, RADIALISTA, COMENTARISTA DE RADIO E TV
          </option>
          <option value={74}>REPRESENTANTE COMERCIAL, CAIXEIRO VIAJANTE</option>
          <option value={75}>NÃO CLASSIFICADO</option>
          <option value={76}>OUTROS GRUPO 15</option>
          <option value={77}>
            CALCETEIRO, CANTEIRO, MANOBRISTA, VIDRACEIRO
          </option>
          <option value={78}>MODELO DE MODAS</option>
          <option value={79}>FISCAL DE TRAFEGO E OUTROS</option>
          <option value={80}>
            VIGIA, VIGILANTE, GUARDA, SEGURANÇA, BOMBEIRO
          </option>
          <option value={81}>OUTROS GRUPO 12</option>
          <option value={84}>VETERINÁRIO, ZOOTÉCNICO</option>
          <option value={85}>GEÓLOGO</option>
          <option value={86}>OUTROS GRUPO 13</option>
          <option value={87}>EMPREGADOS DOMÉSTICOS EM GERAL</option>
          <option value={88}>SACERDOTE, PADRE, PASTOR</option>
          <option value={89}>SOCIÓLOGO</option>
          <option value={90}>ATUARIO, MATEMÁTICO</option>
          <option value={91}>ATLETA PROFISSIONAL E TÉCNICO EM DESPORTOS</option>
          <option value={92}>
            MINISTRO DE TRIBUNAL SUPERIOR, JUIZ, DESEMBARGADOR
          </option>
          <option value={93}>
            SECRETARIA, ESTENOGRAFA, DATILOGRAFA, RECEPCIONISTA, TELEFONISTA,
            OPER DE TMK
          </option>
          <option value={94}>
            AUX DE ESCRIT, VENDAS, ADMINIST., ALMOXARIF, ASSISTENTE, CALCULISTA,
            BILHETEIRO
          </option>
          <option value={95}>DIGITADOR</option>
          <option value={96}>OUTROS TÉCNICOS DE NIVEL MEDIO</option>
          <option value={97}>
            PRAÇA (SOLDADO / CABO / SARGENTO / SUBTENENTE) DA PM
          </option>
          <option value={98}>TRAB.CONTÁBIL / CAIXAS E ASSEMELHADOS</option>
          <option value={100}>
            EMPREGADO DE RESTAURANTE, BUFFET, LANCHONETE, PADARIA E REFEIÇÕES
            INDUSTRIAIS
          </option>
          <option value={101}>
            COSTUREIRA / BORDADEIRA / TAPECEIRA / OVERLOQUISTA / PESPONTADEIRA
          </option>
          <option value={102}>
            ATENDENTE DE ENFERMAGEM / AUXILIAR DE ENFERMAGEM / AUX CRECHE
          </option>
          <option value={103}>
            VIVE DE RENDA /ALUGUEL / SÓCIO / PROPRIETÁRIO
          </option>
          <option value={104}>DONA DE CASA / PRENDA DOMÉSTICAS</option>
          <option value={105}>OPERADOR DE MÁQUINAS DE PRODUÇÃO EM GERAL</option>
          <option value={106}>
            CHEFE INTERM/ENCARREGADO SERV/ SUPERV /CHEFES DE SEÇÃO
          </option>
          <option value={107}>
            PORTEIRO / ASCENSORISTA / GARAGISTA / FAXINEIRO
          </option>
          <option value={108}>
            AUX ESCRITÓRIO/ AUX ALMOXARIFADO / CONFERENTE / BILHETEIRO
          </option>
          <option value={109}>EM BRANCO</option>
          <option value={110}>GERENTE</option>
          <option value={111}>
            VENDEDOR AUTÔNOMO/AMBULANTE/CAMELÔS/SACOLEIRAS
          </option>
          <option value={112}>VENDEDOR COMÉRCIO VAREJISTA E ATACADISTA</option>
          <option value={113}>
            MOTORISTA EMPREGADO DO TRANSPORTE DE PASSAGEIROS
          </option>
          <option value={114}>AUXILIAR DE COZINHA/COSTURA/LIMPEZA/COPA</option>
          <option value={115}>
            AJUD.GERAL/AUX.SERV.GERAIS/AJ DE PEDREIRO/SERVENTE/AJ DE MOTORISTA
          </option>
          <option value={116}>
            OFFICE BOY/CONTÍNUOS/MOTOCICLISTA/CICLISTA
          </option>
        </select>
      </div>

      <input
        type="hidden"
        name="siglaNacionalidade"
        value={portadorAtual.siglaNacionalidade}
        defaultValue={"BRA"}
        onChange={onFieldChange}
        required
      ></input>

      <div className="input-group">
        <label className="label">Email</label>
        <input
          className="input"
          type="Email"
          name="email"
          value={portadorAtual.email}
          onChange={onFieldChange}
          required
        ></input>
      </div>
      <div className="input-group">
        <label className="label">Renda</label>
        <input
          className="input only-number"
          type="number"
          inputMode={"numeric"}
          name="valorRenda"
          min={100}
          step={100}
          value={portadorAtual.valorRenda}
          onChange={onFieldChange}
          onInput={onFieldChange}
          pattern="[0-9]"
          required
        ></input>
        <div className="input-group">
          <input
            className="input"
            type="hidden"
            name="numeroDaAgencia"
            value={portadorAtual.numeroDaAgencia}
            onChange={onFieldChange}
          ></input>
        </div>
        <div className="input-group">
          <input
            className="input"
            type="hidden"
            name="numerodaContaCorrente"
            value={portadorAtual.numerodaContaCorrente}
            onChange={onFieldChange}
          ></input>
        </div>

        <div className="input-group">
          <input
            className="input"
            type="hidden"
            name="nomeEmpresa"
            value={portadorAtual.nomeEmpresa}
            onChange={onFieldChange}
          ></input>
        </div>
        <div className="input-group">
          <input
            className="input"
            type="hidden"
            name="nome"
            minLength={1}
            maxLength={20}
            value={portadorAtual.nome}
            onChange={onFieldChange}
            disabled
          ></input>
        </div>
      </div>
      <br></br>
      <button className="btn btn-secondary" type="submit">
        Avançar
      </button>
    </form>
  );
};

export default Step1;
