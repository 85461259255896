export function MascaraRg(rg, errChar = "") {
  const retorno = rg
  .toString()
  .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(-\d{1})\d+?$/, "$1");
return retorno;
}

export function MascaraInteiro(moeda, errChar = "") {
  const retorno = moeda.toString().replace(",", "").replace(".", "");
  return retorno;
}

//adiciona mascara de cnpj
export function MascaraCNPJ(cnpj) {
  return formataCampo(cnpj, "00.000.000/0000-00");
}

//adiciona mascara de cep
export function MascaraCep(cep) {
  return formataCampo(cep, "00.000-000");
}

//adiciona mascara de data
export function MascaraData(data) {
  return formataCampo(data, "00/00/0000");
}

//adiciona mascara ao telefone
export function MascaraTelefone(tel) {
  return formataCampo(tel, "(00) 0000-0000");
}

//adiciona mascara ao CPF
export function MascaraCPF(cpf) {
  const retorno = cpf
    .toString()
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
  
  return retorno;
}

//valida telefone
export function ValidaTelefone(tel) {
  const exp = /\(\d{2}\)\ \d{4}\-\d{4}/;
  if (!exp.test(tel.value)) alert("Numero de Telefone Invalido!");
}

//valida CEP
export function ValidaCep(cep) {
  const exp = /\d{2}\.\d{3}\-\d{3}/;
  if (!exp.test(cep.value)) alert("Numero de Cep Invalido!");
}

//valida data
export function ValidaData(data) {
  const exp = /\d{2}\/\d{2}\/\d{4}/;
  if (!exp.test(data.value)) alert("Data Invalida!");
}

//valida o CPF digitado
export function ValidarCPF(Objcpf) {
  var cpf = Objcpf.value;
  const exp = /\.|\-/g;
  cpf = cpf.toString().replace(exp, "");
  var digitoDigitado = eval(cpf.charAt(9) + cpf.charAt(10));
  var soma1 = 0,
    soma2 = 0;
  var vlr = 11;

  for (var i = 0; i < 9; i++) {
    soma1 += eval(cpf.charAt(i) * (vlr - 1));
    soma2 += eval(cpf.charAt(i) * vlr);
    vlr--;
  }
  soma1 = (soma1 * 10) % 11 == 10 ? 0 : (soma1 * 10) % 11;
  soma2 = ((soma2 + 2 * soma1) * 10) % 11;

  var digitoGerado = soma1 * 10 + soma2;
  if (digitoGerado != digitoDigitado) alert("CPF Invalido!");
}

//valida o CNPJ digitado
function ValidarCNPJ(ObjCnpj) {
  var cnpj = ObjCnpj.value;
  var valida = new Array(6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2);
  var dig1 = new Number();
  var dig2 = new Number();

  const exp = /\.|\-|\//g;
  cnpj = cnpj.toString().replace(exp, "");
  var digito = new Number(eval(cnpj.charAt(12) + cnpj.charAt(13)));

  for (var i = 0; i < valida.length; i++) {
    dig1 += i > 0 ? cnpj.charAt(i - 1) * valida[i] : 0;
    dig2 += cnpj.charAt(i) * valida[i];
  }
  dig1 = dig1 % 11 < 2 ? 0 : 11 - (dig1 % 11);
  dig2 = dig2 % 11 < 2 ? 0 : 11 - (dig2 % 11);

  if (dig1 * 10 + dig2 != digito) alert("CNPJ Invalido!");
}

//formata de forma generica os campos
function formataCampo(campo, Mascara) {
  if (!campo || !Mascara) {
    return "";
  }
  var boleanoMascara;

  const exp = /\-|\.|\/|\(|\)| /g;
  const campoSoNumeros = campo.replace(exp, "");

  var posicaoCampo = 0;
  var NovoValorCampo = "";
  var TamanhoMascara = campoSoNumeros.length;

  for (var i = 0; i <= TamanhoMascara; i++) {
    boleanoMascara =
      Mascara.charAt(i) == "-" ||
      Mascara.charAt(i) == "." ||
      Mascara.charAt(i) == "/";
    boleanoMascara =
      boleanoMascara ||
      Mascara.charAt(i) == "(" ||
      Mascara.charAt(i) == ")" ||
      Mascara.charAt(i) == " ";
    if (boleanoMascara) {
      NovoValorCampo += Mascara.charAt(i);
      TamanhoMascara++;
    } else {
      NovoValorCampo += campoSoNumeros.charAt(posicaoCampo);
      posicaoCampo++;
    }
  }
  campo = NovoValorCampo;
  return campo;
}
