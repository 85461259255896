import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { validarConta } from "../../services/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const ValidaCadastro = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const hash = queryParams.get("hash");

  const [validacao, setValidacao] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const validaCadastro = async () => {
      try {
        setValidacao(await validarConta(email, hash));
        if (validacao === true) {
          console.log("Cadastro validado com sucesso!");
        } else {
          toast.error("Codigo de validação invalido!");
        }
        navigate("/login");
      } catch (error) {
        setValidacao(false);
        navigate("/login")
        console.error("Erro ao validar o cadastro:", error);
      }
    };

    validaCadastro();
    if (validacao === true) {
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    }
  }, [validacao, email, hash]);

  if (validacao === false) {
    return <div>Validando...</div>;
  } else if (validacao === true) {
    return <div>Cadastro validado com sucesso!</div>;
  } else {
    return <div>Erro ao validar o cadastro. Por favor, tente novamente.</div>;
  }
};

export default ValidaCadastro;
