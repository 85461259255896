import React from "react";
import cartaoApi from "../../services/cartaodecredito.js";
import { useState } from "react";
import { useQuery } from "react-query";
import Loading from "../loading/loading.js";
import "./SaldoLimiteCartao.css";

const SaldoLimiteCartao = (props) => {
  const codigodocartao = props.codigodocartao;
  const setSaldo = props.setSaldo;
  const setLimite = props.setLimite;
  const limiteConta = props.limiteConta;
  const [percentageDispo, setPercentageDispo] = useState(0);
  const [percentageUsed, setPercentageUsed] = useState(0);

  const { data: saldoLimite, isFetching: fetchingSaldoLimite } = useQuery<any>(
    "saldoLimite",
    async () => {
      const url = "cartaodecredito/LimiteCartao/" + codigodocartao;
      const result = await cartaoApi.get(url);
      const data = result.data.conteudo;
      setSaldo(data.saldoDisponivelMensal);
      setLimite(data.limiteMensal);
      setPercentageDispo(
        ((data.limiteMensal - data.saldoDisponivelMensal) / data.limiteMensal) *
          100
      );
      setPercentageUsed((data.limiteMensal / limiteConta) * 100);
      return data;
    },
    {
      enabled: !!codigodocartao && codigodocartao !== "undefined",
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 30,
    }
  );

  return fetchingSaldoLimite ? (
    <Loading />
  ) : (
    <div className="limites">
      <div className="campoValor">
        <div className="displayLimiteInfo">
          <div className="labelValorLimite">Limite disponível</div>

          <div className="valorLimite">
            {saldoLimite?.saldoDisponivelMensal.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </div>
        </div>
        <div className="progress-container">
          <div
            className="progress-bar"
            style={{ width: `${percentageDispo}%` }}
          ></div>
        </div>
      </div>

      <div className="campoValor">
        <div className="displayLimiteInfo">
          <div className="labelValorLimite">Limite utilizado </div>{" "}
          <div className="valorLimite">
            {saldoLimite?.limiteMensal.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </div>
        </div>
        <div className="progress-container">
          <div
            className="progress-bar2"
            style={{ width: `${percentageUsed}%` }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default SaldoLimiteCartao;
