import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { isAuthenticated } from "../services/auth";
import { useEffect } from "react";
import NavRendPay from "./navrendpay/navrendpay";
import "./ProtectedRoute.css";

const ProtectedRoute = () => {
  const logged = isAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (!logged) {
      navigate("/login");
    }
  }, [logged, navigate]);

  return logged ? (
    <div>
        <Outlet />
    </div>
  ) : null;
};

export default ProtectedRoute;
